import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getNetwork } from '../redux/networks/networkActions';
import EXC from '../assets/images/swap-icon.png';
import DoubleArrow from '../assets/images/double-arrows.png';
import { getCurrency } from '../redux/currencies/currencyActions';
import { getAccount, transferAmounts } from '../redux/account/accountActions';
import FullPageLoader from './FullPageLoader/fullPageLoader';
import { GetCoinImg } from '../helpers/getCoinImg';
import { nonNegativeAmount } from '../redux/apiHelper';
import { toFixed } from '../helpers/utils';

function WalletTransfer({ portfolio }) {
  const { t } = useTranslation();
  const [showWalletTransfer, setShowWalletTransfer] = useState(false);
  const [from, setFrom] = useState(1); // 1= Spot, 2= Futures, 3 = Forex
  const [to, setTo] = useState(2); // 1= Spot, 2= Futures, 3 = Forex
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [haveAmount, setHaveAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [amountType, setAmountType] = useState('');

  const handleClose = () => setShowWalletTransfer(false);
  const handleShow = () => setShowWalletTransfer(true);

  const dispatch = useDispatch();
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const userId = useSelector((state) => state.user?.user?._id);
  const amountTransferred = useSelector((state) => state.accounts?.amountTransferred);

  useEffect(() => {
    dispatch(getNetwork());
    dispatch(getCurrency());
  }, []);

  useEffect(() => {
    if (userId) dispatch(getAccount(userId));
  }, [userId]);

  useEffect(() => {
    if (amountTransferred) {
      setLoader(false);
      setSelectedCurrency('');
      setAmount(0);
      setHaveAmount(0);
      handleClose();
    }
  }, [amountTransferred]);

  const amoutTypeHandler = () => {
    const selectedAmount = amounts?.find((row) => row.currencyId === selectedCurrency._id);

    setHaveAmount(
      from === 1 ? nonNegativeAmount(selectedAmount?.amount)
        : from === 2 ? nonNegativeAmount(selectedAmount?.futures_amount)
          : nonNegativeAmount(selectedAmount?.cfd_amount),
    );
  };

  useEffect(() => {
    if (currencyData?.length > 0 && amounts?.length > 0) {
      const defCurrency = currencyData?.find((cur) => cur?.symbol === 'USDT');
      setSelectedCurrency(defCurrency);
    }
  }, [currencyData, amounts]);

  useEffect(() => {
    if (selectedCurrency && selectedCurrency._id) {
      amoutTypeHandler();
    }
  }, [from, to, selectedCurrency]);

  const userCoinChange = (currency) => {
    setSelectedCurrency(currency);
    setAmount(0);
    setAmountType('');
  };

  const exchange = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };

  const handleTransfer = () => {
    const data = {
      userId,
      currencyId: selectedCurrency._id,
      to,
      from,
      amount: parseFloat(amount).toFixed(4),
    };
    dispatch(transferAmounts(data));
    setLoader(true);
  };

  const handleAmountButtonClick = (type) => {
    const newAmount = haveAmount > 0
      ? type === 'min'
        ? parseFloat(selectedCurrency.minAmount)
        : type === 'half'
          ? parseFloat(haveAmount) / 2
          : parseFloat(haveAmount)
      : 0;

    setAmount(newAmount.toFixed(selectedCurrency.isFiat ? 2 : 4));
    setAmountType(type);
  };

  return (
    loader ? (
      <FullPageLoader />
    ) : (
      <>
        {portfolio
          ? <img src={DoubleArrow} alt="DoubleArrow" style={{ cursor: 'pointer', height: '30px' }} onClick={handleShow} />
          : (
            <p style={{ color: '#0FC881', cursor: 'pointer' }} onClick={handleShow}>
              {t('labels.transfer_assets')}
            </p>
          )}

        {/* ================================== Transfer Modal ====================== */}

        <Modal
          className="transfer-modal"
          show={showWalletTransfer}
          onHide={handleClose}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="transfer-modal-content text-center">
              <h4 className="text-white">{t('labels.transfer')}</h4>
              <div className="input-text-dropdown mb-2">
                <p className="m-0 text-white">{t('labels.from')}</p>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle text-white"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {from ? (from === 1 ? t('labels.spot') : (from === 2 ? t('labels.futures') : t('labels.cfd'))) : t('labels.select')}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {to !== 1 ? (
                      <li onClick={() => setFrom(1)}>
                        <a className="dropdown-item">{t('labels.spot')}</a>
                      </li>
                    ) : null}
                    {to !== 2 ? (
                      <li onClick={() => setFrom(2)}>
                        <a className="dropdown-item">{t('labels.futures')}</a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="text-center mt-3 mb-3">
                <img
                  style={{ width: '30px' }}
                  src={EXC}
                  className="img-fluid"
                  alt=""
                  onClick={() => {
                    if (from && to) exchange();
                  }}
                />
              </div>
              <div className="input-text-dropdown mb-2">
                <p className="m-0 text-white">{t('labels.to')}</p>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle text-white"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {to ? (to === 1 ? t('labels.spot') : (to === 2 ? t('labels.futures') : t('labels.cfd'))) : t('labels.select')}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {from !== 1 ? (
                      <li onClick={() => setTo(1)}>
                        <a className="dropdown-item">{t('labels.spot')}</a>
                      </li>
                    ) : null}
                    {from !== 2 ? (
                      <li onClick={() => setTo(2)}>
                        <a className="dropdown-item">{t('labels.futures')}</a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <hr className="modal-hr-line mb-0" />
              <div className="d-flex blance-dropdown-wrap mb-5">
                <div className="con-heading-dropdown">
                  <label className="ms-4">{t('labels.coin')}</label>
                  <div className="dropdown transfer-modal-coin-dd">
                    <button
                      className="btn text-white dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="coin-name-img d-flex align-items-center">
                        <img
                          style={{ width: '35px' }}
                          src={GetCoinImg(selectedCurrency?.symbol)}
                          className="img-fluid"
                          alt=""
                        />
                        <p className="mb-0 ms-2">{selectedCurrency?.symbol}</p>
                      </div>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      {currencyData
                          && currencyData.length > 0
                          && currencyData.map((currency, key = 0) => (
                            <li key={key}>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  userCoinChange(currency);
                                }}
                              >
                                <img
                                  src={GetCoinImg(currency?.symbol)}
                                  alt=""
                                  className="img-fluid coin-img pe-1"
                                />
                                {currency.name}
                              </a>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
                <div className="d-flex flex-column w-100">
                  <div className="d-flex justify-content-between">
                    <label className="ms-4">{t('labels.amount')}</label>
                    <label className="me-4">
                      {`${t('labels.balance')}:`}
                      {' '}
                      {haveAmount ? (
                        selectedCurrency.isFiat ? toFixed(haveAmount, 2) : toFixed(haveAmount, 2)
                      ) : (
                        0
                      )}
                    </label>
                  </div>
                  <input
                    type="number"
                    className="ps-3 pe-3"
                    value={toFixed(amount, 2)}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="min-half-max-btns">
                <button
                  type="button"
                  className={`btn ${amountType === 'min' ? 'text-green' : ''}`}
                  onClick={() => handleAmountButtonClick('min')}
                >
                  {t('labels.min')}
                </button>
                <button
                  type="button"
                  className={`btn  btn-hlf ${amountType === 'half' ? 'text-green' : ''}`}
                  onClick={() => handleAmountButtonClick('half')}
                >
                  {t('labels.half')}
                </button>
                <button
                  type="button"
                  className={`btn ${amountType === 'max' ? 'text-green' : ''}`}
                  onClick={() => handleAmountButtonClick('max')}
                >
                  {t('labels.max')}
                </button>
              </div>
              <div className="btn-wrapper">
                <button
                  type="button"
                  className="btn confirm-btn"
                  onClick={() => handleTransfer()}
                  disabled={
                      !(
                        to
                        && from
                        && selectedCurrency
                        && parseFloat(amount) >= parseFloat(selectedCurrency?.minAmount)
                        && parseFloat(amount) <= parseFloat(haveAmount)
                      )
                    }
                >
                  {t('labels.confirm')}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  );
}

export default WalletTransfer;
